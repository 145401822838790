import dayjs from "dayjs"
import { useSelector } from "react-redux"
import { ImSpinner7 } from "react-icons/im"
import GlobalContext from "./GlobalContext"
import React, { useState, useEffect, useMemo } from "react"
import { isDesktop, isMobileOnly, isTablet, osName, mobileVendor, browserName, browserVersion } from 'react-device-detect'

function getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res
}

function detectDevice() {
    var currentDevice = {}
    if (isDesktop) {
        currentDevice['device'] = 'Computadora'
        currentDevice['mobilevendor'] = 'N/A'
    } else if (isMobileOnly) {
        currentDevice['device'] = 'Celular'
        currentDevice['mobilevendor'] = mobileVendor
    } else if (isTablet) {
        currentDevice['device'] = 'Tablet'
        currentDevice['mobilevendor'] = mobileVendor
    }

    currentDevice['osystem'] = osName
    currentDevice['browser'] = browserName + ' ' + browserVersion

    return currentDevice;
}

function waitMessage() {
    return (
        <span>
            <span style={{ opacity: '0.85' }}>Espera por favor</span>
            <ImSpinner7 className="spin ms-3" size={15} />
        </span>
    )
}

function deleteCookie(cNamer) {
    window.localStorage.removeItem('user_weport_login')
    window.location = '/';
    return document.cookie = cNamer + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export default function ContextWrapper(props) {
    const [monthIndex, setMonthIndex] = useState(dayjs().month());
    const [smallCalendarMonth, setSmallCalendarMonth] = useState(null);
    const [daySelected, setDaySelected] = useState(dayjs());
    const [showEventModal, setShowEventModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [labels, setLabels] = useState([]);
    const [arrayAttendees, setArrayAttendees] = useState([])
    const [selectedImageUser, setSelectedImageUser] = useState('')
    const [myEvents, getMyEvents] = useState(false);

    const [gtEventsC, getEventsC] = useState([])

    const { events = [] } = useSelector(state => state.events)
    const { salas = [] } = useSelector(state => state.salas)

    const filteredEvents = useMemo(() => {
        return events.filter((evt) =>
            labels
                .filter((lbl) => lbl.checked)
                .map((lbl) => lbl.label)
                .includes(evt.room)
        );
    }, [events, labels]);

    useEffect(() => {
        setSelectedImageUser(localStorage.imageUserProfile)
    }, [setSelectedImageUser]);

    useEffect(() => {
        setLabels((prevLabels) => {
            return [...new Set(salas.map((evt) => evt.name))].map(
                (label) => {
                    const currentLabel = prevLabels.find(
                        (lbl) => lbl.label === label
                    );
                    return {
                        label,
                        checked: currentLabel ? currentLabel.checked : true,
                    };
                }
            );
        });
    }, [salas]);

    useEffect(() => {
        if (smallCalendarMonth !== null) {
            setMonthIndex(smallCalendarMonth);
        }

        if (!showEventModal) {
            setSelectedEvent(null);
        }
    }, [smallCalendarMonth, showEventModal]);

    function updateLabel(label) {
        setLabels(
            labels.map((lbl) => (lbl.label === label.label ? label : lbl))
        );
    }

    return (
        <GlobalContext.Provider
            value={{
                monthIndex,
                setMonthIndex,
                smallCalendarMonth,
                setSmallCalendarMonth,
                daySelected,
                setDaySelected,
                showEventModal,
                setShowEventModal,
                selectedEvent,
                setSelectedEvent,
                setLabels,
                labels,
                updateLabel,
                filteredEvents,
                arrayAttendees,
                setArrayAttendees,
                getCookie,
                deleteCookie,
                selectedImageUser,
                setSelectedImageUser,
                detectDevice,
                waitMessage,
                gtEventsC,
                getEventsC,
                myEvents,
                getMyEvents,
            }}
        >
            {props.children}
        </GlobalContext.Provider>
    );
}