import axios from "axios"
import { gapi } from 'gapi-script'
import { useState, useEffect } from "react"
import { useParams } from 'react-router-dom'
import { faArrowUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CLIENT_ID = '976244262981-tidfhtrbhbbtemid02a44ogkh0rc8j80.apps.googleusercontent.com'
const API_KEY = 'AIzaSyBzR79TBpzwP_jJS-K1ofFirhZ7Z2csFmc'
const DISCOVERY_DOCS = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'
const SCOPES = 'https://www.googleapis.com/auth/calendar.events'

const CreateEventController = () => {
    /*const md5 = require("blueimp-md5");
    let hashId = md5(eventid + '' + Math.floor(Math.random() * 9), "ourcodeworld");*/
    const { eventid } = useParams();
    const [gtError, getError] = useState('')

    useEffect(() => {
        axios.get('https://api-intranet.weportdevelopers.com/api/booking/getData/' + eventid, {
            data: {},
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        }).then(function (response) {
            if (response.data.status === 'success') {
                gapi.load('client:auth2', () => {
                    gapi.client.init({
                        apiKey: API_KEY,
                        clientId: CLIENT_ID,
                        discoveryDocs: DISCOVERY_DOCS,
                        scope: SCOPES,
                    })

                    gapi.client.load('calendar', 'v3')

                    gapi.auth2.getAuthInstance().signIn({ prompt: 'select_account' }).then(() => {
                        var event = {
                            'summary': response.data.responses.booking.title,
                            'location': 'Perif. Blvd. Manuel Ávila Camacho 118, Lomas de Chapultepec, Miguel Hidalgo, 11000 Ciudad de México, CDMX',
                            'description': '',
                            'guestsCanInviteOthers': false,
                            'guestsCanSeeOtherGuests': false,
                            'start': {
                                'dateTime': response.data.responses.booking.start.replace(' ', 'T'),
                                'timeZone': 'America/Mexico_City'
                            },
                            'end': {
                                'dateTime': response.data.responses.booking.end.replace(' ', 'T'),
                                'timeZone': 'America/Mexico_City'
                            },
                            'recurrence': [
                                'RRULE:FREQ=DAILY;COUNT=1'
                            ],
                            'reminders': {
                                'useDefault': false,
                                'overrides': [
                                    { 'method': 'email', 'minutes': 24 * 60 },
                                    { 'method': 'popup', 'minutes': 10 }
                                ]
                            },
                        }

                        var request = gapi.client.calendar.events.insert({
                            'calendarId': 'primary',
                            'resource': event,
                        })

                        request.execute(event => {
                            window.location.assign(event.htmlLink);
                        })
                    }).catch(function (error) {
                        getError(error.error)
                    })
                })
            } else {
                window.location = '/'
            }
        }).catch(function (error) {
            console.log(error)
        });
    })

    return (
        <div className="ContarinerLoading">
            ({typeof gtError !== 'undefined' ?
                (gtError === 'popup_blocked_by_browser' ?
                    <div className="help-modal">
                        <div className="help-modal-icon">
                            <FontAwesomeIcon icon={faArrowUp} className='help-modal-icon-fa' />
                            <FontAwesomeIcon icon={faArrowUp} className='help-modal-icon-fa' />
                            <FontAwesomeIcon icon={faArrowUp} className='help-modal-icon-fa' />
                            <FontAwesomeIcon icon={faArrowUp} className='help-modal-icon-fa' />
                        </div>
                        <img src="/logotipo-weport.png" alt="" />
                        <div className="help-modal-h2">
                            <h2>Favor de habilitar las ventanas emergentes y posteriormente recarga la pagina</h2>
                            <br />
                            <h4>Da clic en el icono de las ventanas emergentes</h4>
                            <img src="/paso1.png" alt="paso 1" />
                            <h4>Selecciona la primera opción y posteriormente da en Guardar / Aplicar / Hecho</h4>
                            <img src="/paso2.png" alt="paso 2" />
                            <br />
                            <h4>Para finalizar recarga la página o da clic <a href={(window.location)}>aquí</a></h4>
                        </div>
                    </div>
                    : (gtError === 'popup_closed_by_user' && (window.location = '/')))
                : <img src="/loading.gif" alt="Logo Loading Weport" id="img-load-cal" />})
        </div>
    )
}

export default CreateEventController