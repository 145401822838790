import { useContext } from 'react'
import { QueryClient } from 'react-query'
import React, { Suspense, lazy } from 'react'
import { QueryClientProvider } from 'react-query'
import LayoutStart from '../components/LayoutStart'
import GlobalContext from '../context/GlobalContext'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Routes, Route, Navigate } from 'react-router-dom'
import CreateEventController from '../pages/EventsAPI/CreateEventController'
import DeleteEventController from '../pages/EventsAPI/DeleteEventController'

const LoginController = lazy(() => import('../pages/login/LoginController'))
const SignUp = lazy(() => import('../pages/SignUp'))
const ConfirmCount = lazy(() => import('../pages/ConfirmCount'))
const ResetPasswordController = lazy(() => import('../pages/ResetPassword/ResetPasswordController'))

const queryClient = new QueryClient()

const RoutesApp = () => {
	const { getCookie } = useContext(GlobalContext)

	if (typeof getCookie('_WPINET') === 'undefined') {
		return (
			<Suspense fallback={<div>Loading...</div>}>
				<Routes>
					<Route path='/' element={<LayoutStart />}>
						<Route key='init-path' path='' element={<Navigate to="/login" replace />} />
						<Route key='registro-path' path='registro' element={<SignUp />} />
						<Route key='login-path' path='login' element={<LoginController />} />
						<Route key='confirmar-path' path='cuenta/confirmar/:token' element={<ConfirmCount />} />
						<Route key='creat-event-path' path='creat-event/:eventid' element={<CreateEventController />} />
						{/* <Route key='creat-event-path' path='creat-event/:summary/:location/:description/:init/:end' element={<CreateEventController />} /> */}
						<Route key='delete-event-path' path='delete-event/:eventId' element={<DeleteEventController />} />
						<Route key='reset-password-path' path='reset-password/:codeUuid' element={<ResetPasswordController />} />
					</Route>
					<Route path="*" element={<Navigate to="/login" replace />} />
				</Routes>
			</Suspense>
		)
	} else {
		const Routing = lazy(() => import('./routing'))

		return (
			<QueryClientProvider client={queryClient} >
				<Suspense fallback={<div>Loading...</div>}>
					<Routing />
				</Suspense>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		);
	}
}

export default RoutesApp;