import React, {useState} from 'react'

const ContextSections = React.createContext({});

export function SectionsContext({children}) {
    const [ titlesection, setSectionTitle ] = useState(
        () => 'Default'
    )

    const [ showmenu, setShowMenu ] = useState(
        () => false
    )

    const [ stCookieSesion, setCookieSesion ] = useState(
        () => document.cookie = ''
    )

    return <ContextSections.Provider value={{titlesection, setSectionTitle, showmenu, setShowMenu, stCookieSesion, setCookieSesion}}>
        {children}
    </ContextSections.Provider>
}

export default ContextSections