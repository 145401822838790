import { createSlice } from "@reduxjs/toolkit"

export const salasCalendarSlice = createSlice({
    name: 'salas',
    initialState: {
        isLoading: false,
        salas: [],
    },
    reducers: {
        startLoadingSalas: (state) => {
            state.isLoading = true
        },
        setSalas: (state, action) => {
            state.isLoading = false
            state.salas = action.payload
        },
    }
})

export const { startLoadingSalas, setSalas } = salasCalendarSlice.actions