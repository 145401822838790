import { gapi } from 'gapi-script'
import { useParams } from 'react-router-dom'

const CLIENT_ID = '976244262981-tidfhtrbhbbtemid02a44ogkh0rc8j80.apps.googleusercontent.com'
const API_KEY = 'AIzaSyBzR79TBpzwP_jJS-K1ofFirhZ7Z2csFmc'
const DISCOVERY_DOCS = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'
const SCOPES = 'https://www.googleapis.com/auth/calendar.events'

const DeleteEventController = () => {
    const { eventId } = useParams();

    gapi.load('client:auth2', () => {
        gapi.client.init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            discoveryDocs: DISCOVERY_DOCS,
            scope: SCOPES,
        })

        gapi.client.load('calendar', 'v3', () => console.log(''))

        gapi.auth2.getAuthInstance().signIn()
            .then(() => {
                var request = gapi.client.calendar.events.delete({
                    'calendarId': 'primary',
                    'eventId': eventId,
                })

                request.execute(event => {
                    window.close()
                })

            })
    })

    return (
        <div className="ContarinerLoading">
            <img src="/loading.gif" alt="Logo Loading Weport" />
        </div>
    )
}

export default DeleteEventController