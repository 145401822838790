import "./index.css"
import './assets/styles/app.css'
import { store } from "./store/store"
import { Provider } from "react-redux"
import ReactDOM from "react-dom/client"
import RoutesApp from './config/RoutesApp'
import 'bootstrap/dist/css/bootstrap.min.css'
import ContextWrapper from "./context/ContextWrapper"
import { BrowserRouter as Router } from 'react-router-dom'
import { SectionsContext } from "./context/SectionContext"
import { QueryClient, QueryClientProvider } from 'react-query'

const root = ReactDOM.createRoot(
	document.getElementById("root")
)

const queryClient = new QueryClient()

root.render(
	<SectionsContext>
		<Provider store={store}>
			<QueryClientProvider client={queryClient}>
				<ContextWrapper>
					<Router>
						<RoutesApp />
					</Router>
				</ContextWrapper>
    		</QueryClientProvider>
		</Provider>
	</SectionsContext>
)