import axios from "axios"
import { startLoadingEvents, setEvents } from "./eventsCalendarSlice"

function getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res
}

export const getEventsCalendar = () => async (dispatch, getState) => {
    dispatch(startLoadingEvents())

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getCookie('_WPINET')
    const references = await axios.get('https://api-intranet.weportdevelopers.com/api/account/schedule', {
        data: {},
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    }).then(function (response) {
        return response.data
    }).catch(function (error) {
        if (error.response.data.message) {
            document.cookie = '_WPINET=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            window.location = '/';
        }
    });

    dispatch(setEvents(references.responses.bookings))
}