import axios from "axios"
import { startLoadingSalas, setSalas } from "./salasCalendarSlice"

export const getSalasCalendar = () => {
    return async (dispatch, getState) => {
        dispatch(startLoadingSalas())

        const references = await axios.get('https://api-intranet.weportdevelopers.com/api/catalogues/salas', {
            data: {},
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        }).then(function (response) {
            return response.data
        }).catch(function (error) {
            if (error.response.data.message) {
                document.cookie = '_WPINET=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                window.location = '/';
            }
        });

        dispatch(setSalas(references.catalogue))
    }
}