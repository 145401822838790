import { createSlice } from "@reduxjs/toolkit"

export const eventsCalendarSlice = createSlice({
    name: 'events',
    initialState: {
        isLoading: false,
        events: [],
    },
    reducers: {
        startLoadingEvents: (state) => {
            state.isLoading = true
        },
        setEvents: (state, action) => {
            state.isLoading = false
            state.events = action.payload
        },
    }
})

export const { startLoadingEvents, setEvents } = eventsCalendarSlice.actions